import React from "react";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import FaqAccordion from "../../../components/faq/faq-accordion";

// Helpers
import getSortedFaqDataByCategory from "../../../helpers/getSortedFaqDataByCategory";

const CreditCardFaq = () => {
  const { sortedFaqs, categoryFootnotes, schema } = getSortedFaqDataByCategory(["Credit Cards"]);

  const title = "Cash Back Credit Card - FAQs",
    description =
      "Learn about WaFd Bank's cash back credit card and find out if this is the right rewards credit card to fit your needs. Visit our FAQs page for more information.";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/credit-cards/cash-back-credit-card-faq"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ],
    schema
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/credit-cards",
      title: "Credit Cards"
    },
    {
      id: 3,
      active: true,
      title: "FAQs"
    }
  ];

  const faqAccordionData = {
    id: "personal-credit-cards-faq-section",
    pageTitle: "WaFd Bank Cash Back Credit Card",
    title: null,
    // pass faqData instead of faqCategoryNames
    faqData: {
      sortedFaqs,
      categoryFootnotes
    }
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};
export default CreditCardFaq;
